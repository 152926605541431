import { defineComponentClasses } from '@visiontree/vue-ui'

interface Structure {
  container: never
}

type Options = object

export const getContainerClasses = defineComponentClasses<Options, Structure>({
  classes: {
    container: 'container mx-auto',
  },
})
