<template>
  <component :is="tagName" :class="classes.container">
    <slot />
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { getContainerClasses } from './utils/getContainerClasses'

interface ContainerProps {
  tagName?: string
}

withDefaults(defineProps<ContainerProps>(), {
  tagName: 'div',
})

const classes = computed(() => {
  return getContainerClasses()
})
</script>

<script lang="ts">
export default {
  name: 'VtSection',
}
</script>
